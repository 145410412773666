import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useCookie } from "next-cookie";
import PackageCard from "./PackageCard";
import { PACKAGE_PRICES } from "../../action/packages";

const PackageSection = () => {
  const { t } = useTranslation("packages");
  const router = useRouter();

  const [getPackagePrice, { loading, error, data }] =
    useLazyQuery(PACKAGE_PRICES);

  const cookie = useCookie();
  const lat = cookie.get("lat");
  const lng = cookie.get("lng");
  const language = router.locale === "id" ? "Indonesian" : "English";
  const token = router.query.token;

  useEffect(() => {
    getPackagePrice({
      variables: {
        lat: lat || null,
        lng: lng || null,
        token: token || null,
        language: language || "Indonesian",
        language: language || "Indonesian",
        token: token || null,
      },
    });
  }, [lat, lng, token, language]);

  const dataPackage = error
    ? []
    : data?.package_lists_for_user.filter(
        (pkg) => pkg.package_list.id !== 1 && pkg.package_list.type !== 0
      );

  return (
    <div
      className="paket-bg-img"
      style={{ marginTop: "3.75em", paddingBlock: "6.25em" }}
    >
      <div className="container d-flex flex-column">
        <p
          className="text-center font-roboto"
          style={{
            fontSize: "25px",
            fontWeight: "500",
          }}
        >
          {t("hero_section.title.choose")}{" "}
          <span
            className="text-decoration-underline"
            style={{ fontWeight: "600", color: "#006EA7" }}
          >
            {t("hero_section.title.package")}
          </span>{" "}
          {t("hero_section.title.that suitable for")}{" "}
          <span
            className="text-decoration-underline"
            style={{ fontWeight: "600", color: "#006EA7" }}
          >
            {t("hero_section.title.your needs")}
          </span>
        </p>
        <p
          className="mx-auto text-center"
          style={{ color: "#606060", fontSize: "18px", maxWidth: "60em" }}
        >
          {t("hero_section.description")}
        </p>

        <div>
          <div className={`row gy-5 py-4`}>
            {loading
              ? Array(2)
                  .fill("_")
                  .map((pkg, i) => (
                    <div key={i} className={`col-6`}>
                      <div
                        className="package-card placeholder"
                        style={{ height: "200px" }}
                      ></div>
                    </div>
                  ))
              : dataPackage?.map((pkg, i) => (
                  <div key={i} className={`col-12 col-md-6`}>
                    <PackageCard
                      loading={loading}
                      data={pkg.package_list}
                      token={token}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageSection;
